import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = process.env.REACT_APP_API_URL!

// account
function login(params: { email: string; password: string }) {
    return api.post(`${baseUrl}/login`, params);
}

function logout() {
    return api.get(`${baseUrl}/logout`);
}

function signup(params: { fullname: string; email: string; password: string }) {
    return api.post(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    return api.post(`${baseUrl}`, params);
}

function getLoggedInUser() {
    return api.getLoggedInUser(`${baseUrl}/profile`);
}

export { login, logout, signup, forgotPassword, getLoggedInUser };
