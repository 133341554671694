// all routes
import Routes from './routes/Routes';


// For Default import Theme.scss
import './assets/scss/Theme.scss';
import { useRedux } from './hooks';
import { getUser } from './redux/actions';

const App = () => {
    const { dispatch, appSelector } = useRedux();

    dispatch(getUser());
    
    return <Routes />;
};

export default App;
