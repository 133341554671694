import React, { useEffect, useState } from "react";
import { FlippingCubeLoader } from "react-loaders-kit";
import { useGlobalLoader } from "../hooks/useGlobalLoader";

const loaderProps = {
  loading: true,
  size: 100,
  // duration: 1.5,
  colors: ["#233446", "#233446", "#233446", "#233446"],
};

const Loading = () => {
  const { isLoading } = useGlobalLoader();
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      // If the loader is hiding, add a delay before setting fadeOut to true
      const timeout = setTimeout(() => {
        setFadeOut(true);
      }, 1000); // Adjust the delay time as needed

      return () => clearTimeout(timeout);
    } else {
      setFadeOut(false);
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-container" id="loader">
            <div className="loader-wrap">
              <FlippingCubeLoader {...loaderProps} />
              <p>Loading...</p>
            </div>
          </div>
        </div>
      )}
      {!isLoading && (
        <div
          className={`loader-overlay fade-out ${
            fadeOut ? "fade-out-active" : ""
          }`}
        >
          <div className="loader-container" id="loader">
            <div className="loader-wrap">
              <FlippingCubeLoader {...loaderProps} />
              <p>Loading...</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
